import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

const loader = () => {
  if (!detectMob()) {
    return import("@components/SignIn/NewSignIn")
  } else {
    return import("@components/SignIn/SignInMobile")
  }
}

const SignIn = ReactDynamicImport({ loader })

const SignInPage = ({ location }) => {
  return (
    <>
      <SEO title="Sign In" />
      <SignIn location={location} />
    </>
  )
}

export default SignInPage
